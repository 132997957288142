var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 52 42",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M35.6625 37.1545C41.8633 37.1545 46.8901 32.1277 46.8901 25.9268C46.8901 19.726 41.8633 14.6992 35.6625 14.6992",
          stroke: _vm.color,
          "stroke-width": "2.61343",
          "stroke-linecap": "square",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M49.6974 39.9644L43.5924 33.8594",
          stroke: _vm.color,
          "stroke-width": "2.61343",
          "stroke-linecap": "square",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M39.264 0H28.9846L20.5027 23.0866L19.6039 25.8951L18.7613 23.0866L10.2794 0H0L14.9978 39.3202H24.2662L26.3939 33.7419C24.625 31.5809 23.5636 28.8182 23.5636 25.8077C23.5636 19.55 28.1492 14.3634 34.1434 13.4248L39.264 0ZM27.5187 30.793L33.0185 16.374C29.0469 17.6696 26.177 21.4035 26.177 25.8077C26.177 27.625 26.6656 29.3282 27.5187 30.793Z",
          fill: _vm.color,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }