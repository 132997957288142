var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "register-funnel" }, [
    _c("div", { staticClass: "register-funnel__main-block" }, [
      _c("div", { staticClass: "register-funnel__main-block__inner" }, [
        _c("div", { staticClass: "register-funnel__main-block__heading" }, [
          _vm._m(0),
          _vm.currentStep === 1 && !_vm.IS_LOTTERY_WINNER
            ? _c(
                "div",
                { staticClass: "register-funnel__main-block__heading__auth" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "register-funnel__main-block__heading__auth__text",
                    },
                    [_vm._v(_vm._s(_vm.$t("vue.alreadyHaveAccount")))]
                  ),
                  _c("img", {
                    staticClass:
                      "register-funnel__main-block__heading__auth__icon",
                    attrs: {
                      src: require("@/assets/images/register-funnel/lock.png"),
                      alt: "Secure",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "register-funnel__main-block__heading__auth__link",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/pages/login")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("vue.login")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "register-funnel__main-block__body" },
          [
            _vm.currentStep === 4 &&
            _vm.company &&
            _vm.company.IS_LOTTERY_WINNER
              ? [_c("lottery-congrats-message")]
              : _vm._e(),
            _c(
              "div",
              { staticClass: "register-funnel__main-block__flow" },
              [
                _vm.isBackButton
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "register-funnel__main-block__flow__action-buttons",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "register-funnel__main-block__flow__action-button",
                            on: { click: _vm.prevStep },
                          },
                          [
                            _c("ArrowLongIcon", {
                              staticClass: "mr-2",
                              attrs: {
                                color: "rgb(135, 136, 148)",
                                direction: "left",
                              },
                            }),
                            _vm._v(
                              "\n\n              " +
                                _vm._s(_vm.$t("vue.back")) +
                                "\n            "
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                !_vm.isOpenedFeaturesListOnMobile &&
                !_vm.showVegaActivationMessage
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "register-funnel__main-block__flow__progress-bar-wrapper",
                      },
                      [
                        _c("div", {
                          staticClass:
                            "register-funnel__main-block__flow__progress-bar",
                          style: {
                            width:
                              (_vm.currentStep / (_vm.totalCountOfSteps + 1)) *
                                100 +
                              "%",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm.currentStep === 1
                  ? [
                      _c(
                        "div",
                        {
                          staticClass:
                            "register-funnel__main-block__flow__heading",
                          class: {
                            "register-funnel__main-block__flow__heading--sized":
                              _vm.IS_LOTTERY_WINNER,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "register-funnel__main-block__flow__heading__title",
                              class: {
                                "register-funnel__main-block__flow__heading__title--small":
                                  _vm.IS_LOTTERY_WINNER,
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.IS_LOTTERY_WINNER
                                      ? _vm.$t("vue.registerFunnelDiscount")
                                      : _vm.$t(
                                          "vue.registerFunnelStartYourFreeTrial"
                                        )
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "register-funnel__main-block__flow__content",
                        },
                        [
                          _c("funnel-social-login", {
                            attrs: {
                              type: "register",
                              IS_LOTTERY_WINNER: _vm.IS_LOTTERY_WINNER,
                            },
                            on: { "set-lottery-data": _vm.setLotteryData },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "register-funnel__main-block__flow__content__separator",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "register-funnel__main-block__flow__content__separator__label",
                                },
                                [_vm._v(_vm._s(_vm.$t("vue.or").toLowerCase()))]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "register-funnel__main-block__flow__content__form",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "register-funnel__main-block__flow__content__form__fields",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "register-funnel__main-block__flow__content__form__fields__field",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "register-funnel__main-block__flow__content__form__fields__field__inner",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.name,
                                                expression: "name",
                                              },
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value:
                                                  "validateStringForSpecialCharacters",
                                                expression:
                                                  "'validateStringForSpecialCharacters'",
                                              },
                                            ],
                                            ref: "name",
                                            staticClass:
                                              "register-funnel__main-block__flow__content__form__fields__field__input",
                                            attrs: {
                                              type: "text",
                                              "data-vv-validate-on": "blur",
                                              name: _vm.$t("vue.name"),
                                              "label-placeholder":
                                                _vm.$t("vue.name"),
                                            },
                                            domProps: { value: _vm.name },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.name = $event.target.value
                                              },
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "register-funnel__main-block__flow__content__form__fields__field__label",
                                              class: {
                                                "register-funnel__main-block__flow__content__form__fields__field__label--moved":
                                                  _vm.name &&
                                                  _vm.name.length > 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$refs.name.focus()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "inputs.enterYourName"
                                                    )
                                                  ) +
                                                  "\n                      "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm.errors.first(_vm.$t("vue.name"))
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-danger text-lg flex items-center",
                                              staticStyle: {
                                                "margin-top": "10px",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  src: require("@/assets/images/pages/login/error-icon.svg"),
                                                },
                                              }),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.errors.first(
                                                    _vm.$t("vue.name")
                                                  )
                                                ) + "\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "register-funnel__main-block__flow__content__form__fields__field",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "register-funnel__main-block__flow__content__form__fields__field__inner",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.email,
                                                expression: "email",
                                              },
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required|email",
                                                expression: "'required|email'",
                                              },
                                            ],
                                            ref: "email",
                                            staticClass:
                                              "register-funnel__main-block__flow__content__form__fields__field__input",
                                            attrs: {
                                              type: "email",
                                              "data-vv-validate-on": "blur",
                                              name: _vm.$t("inputs.email"),
                                              "label-placeholder":
                                                _vm.$t("inputs.email"),
                                            },
                                            domProps: { value: _vm.email },
                                            on: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.nextStep.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                              change: function ($event) {
                                                _vm.isUniqueEmailError = false
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.email = $event.target.value
                                              },
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "register-funnel__main-block__flow__content__form__fields__field__label",
                                              class: {
                                                "register-funnel__main-block__flow__content__form__fields__field__label--moved":
                                                  _vm.email &&
                                                  _vm.email.length > 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$refs.email.focus()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "inputs.enterYourEmail"
                                                    )
                                                  ) +
                                                  "\n                      "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm.errors.first(_vm.$t("inputs.email"))
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-danger text-lg flex items-center",
                                              staticStyle: {
                                                "margin-top": "10px",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  src: require("@/assets/images/pages/login/error-icon.svg"),
                                                },
                                              }),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.errors.first(
                                                    _vm.$t("inputs.email")
                                                  )
                                                ) + "\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm.isUniqueEmailError
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-danger text-lg flex items-center",
                                              staticStyle: {
                                                "margin-top": "10px",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  src: require("@/assets/images/pages/login/error-icon.svg"),
                                                },
                                              }),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "vue.emailAlreadyInUse"
                                                  )
                                                ) + "\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.currentStep === 2
                  ? [
                      _c("form", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "register-funnel__main-block__flow__heading",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "register-funnel__main-block__flow__heading__title",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("vue.niceToMeetYou")) +
                                    " " +
                                    _vm._s(_vm.name) +
                                    "!"
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "register-funnel__main-block__flow__heading__description",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("vue.letsSetupYourPassword"))
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "register-funnel__main-block__flow__content",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "register-funnel__main-block__flow__content__form",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "register-funnel__main-block__flow__content__form__fields",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "register-funnel__main-block__flow__content__form__fields__field",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "register-funnel__main-block__flow__content__form__fields__field__inner",
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.password,
                                                  expression: "password",
                                                },
                                                {
                                                  name: "validate",
                                                  rawName:
                                                    "v-validate.continues",
                                                  value:
                                                    "required|min:6|max:128|atLeastOneNumber|atLeastOneUppercase|atLeastOneSpecialCharacter",
                                                  expression:
                                                    "'required|min:6|max:128|atLeastOneNumber|atLeastOneUppercase|atLeastOneSpecialCharacter'",
                                                  modifiers: {
                                                    continues: true,
                                                  },
                                                },
                                              ],
                                              ref: "password",
                                              staticClass:
                                                "register-funnel__main-block__flow__content__form__fields__field__input",
                                              attrs: {
                                                type: "password",
                                                autocomplete: "on",
                                                name: _vm.$t("inputs.password"),
                                                "label-placeholder":
                                                  _vm.$t("inputs.password"),
                                              },
                                              domProps: { value: _vm.password },
                                              on: {
                                                keyup: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.nextStep.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.password =
                                                    $event.target.value
                                                },
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "register-funnel__main-block__flow__content__form__fields__field__label",
                                                class: {
                                                  "register-funnel__main-block__flow__content__form__fields__field__label--moved":
                                                    _vm.password &&
                                                    _vm.password.length > 0,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$refs.password.focus()
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          " +
                                                    _vm._s(
                                                      _vm.$t("inputs.password")
                                                    ) +
                                                    "\n                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm.errors &&
                                        _vm.errors.items &&
                                        _vm.errors.items.find(function (item) {
                                          return (
                                            item.field ===
                                              _vm.$t("inputs.password") &&
                                            ["required", "max"].includes(
                                              item.rule
                                            )
                                          )
                                        })
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-danger text-lg flex items-center",
                                                staticStyle: {
                                                  "margin-top": "10px",
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    src: require("@/assets/images/pages/login/error-icon.svg"),
                                                  },
                                                }),
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.errors.first(
                                                      _vm.$t("inputs.password")
                                                    )
                                                  ) + "\n                      "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "register-funnel__main-block__flow__content__form__fields__field__rules-wrapper",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "register-funnel__main-block__flow__content__form__fields__field__rule",
                                                class: {
                                                  "register-funnel__main-block__flow__content__form__fields__field__rule--valid":
                                                    _vm.password.length >= 6,
                                                  "register-funnel__main-block__flow__content__form__fields__field__rule--invalid":
                                                    _vm.submitPasswordStep &&
                                                    _vm.errors &&
                                                    _vm.errors.items &&
                                                    _vm.errors.items.find(
                                                      function (item) {
                                                        return (
                                                          item.field ===
                                                            _vm.$t(
                                                              "inputs.password"
                                                            ) &&
                                                          ["min"].includes(
                                                            item.rule
                                                          )
                                                        )
                                                      }
                                                    ),
                                                },
                                              },
                                              [
                                                _vm.password.length >= 6
                                                  ? _c("check-icon", {
                                                      staticClass:
                                                        "register-funnel__main-block__flow__content__form__fields__field__rule__icon",
                                                      attrs: {
                                                        color:
                                                          "rgb(65, 184, 131)",
                                                        width: 16,
                                                        height: 16,
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(
                                                  "\n\n                          " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "inputs.minimumCharacters"
                                                      )
                                                    ) +
                                                    "\n                        "
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "register-funnel__main-block__flow__content__form__fields__field__rule",
                                                class: {
                                                  "register-funnel__main-block__flow__content__form__fields__field__rule--valid":
                                                    _vm.password.length &&
                                                    !(
                                                      _vm.errors &&
                                                      _vm.errors.items &&
                                                      _vm.errors.items.find(
                                                        function (item) {
                                                          return (
                                                            item.field ===
                                                              _vm.$t(
                                                                "inputs.password"
                                                              ) &&
                                                            [
                                                              "atLeastOneUppercase",
                                                            ].includes(
                                                              item.rule
                                                            )
                                                          )
                                                        }
                                                      )
                                                    ),
                                                  "register-funnel__main-block__flow__content__form__fields__field__rule--invalid":
                                                    _vm.submitPasswordStep &&
                                                    _vm.errors &&
                                                    _vm.errors.items &&
                                                    _vm.errors.items.find(
                                                      function (item) {
                                                        return (
                                                          item.field ===
                                                            _vm.$t(
                                                              "inputs.password"
                                                            ) &&
                                                          [
                                                            "atLeastOneUppercase",
                                                          ].includes(item.rule)
                                                        )
                                                      }
                                                    ),
                                                },
                                              },
                                              [
                                                _vm.password.length &&
                                                !(
                                                  _vm.errors &&
                                                  _vm.errors.items &&
                                                  _vm.errors.items.find(
                                                    function (item) {
                                                      return (
                                                        item.field ===
                                                          _vm.$t(
                                                            "inputs.password"
                                                          ) &&
                                                        [
                                                          "atLeastOneUppercase",
                                                        ].includes(item.rule)
                                                      )
                                                    }
                                                  )
                                                )
                                                  ? _c("check-icon", {
                                                      staticClass:
                                                        "register-funnel__main-block__flow__content__form__fields__field__rule__icon",
                                                      attrs: {
                                                        color:
                                                          "rgb(65, 184, 131)",
                                                        width: 16,
                                                        height: 16,
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(
                                                  "\n\n                          " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "inputs.atLeastUppercase"
                                                      )
                                                    ) +
                                                    "\n                        "
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "register-funnel__main-block__flow__content__form__fields__field__rule",
                                                class: {
                                                  "register-funnel__main-block__flow__content__form__fields__field__rule--valid":
                                                    _vm.password.length &&
                                                    !(
                                                      _vm.errors &&
                                                      _vm.errors.items &&
                                                      _vm.errors.items.find(
                                                        function (item) {
                                                          return (
                                                            item.field ===
                                                              _vm.$t(
                                                                "inputs.password"
                                                              ) &&
                                                            [
                                                              "atLeastOneNumber",
                                                            ].includes(
                                                              item.rule
                                                            )
                                                          )
                                                        }
                                                      )
                                                    ),
                                                  "register-funnel__main-block__flow__content__form__fields__field__rule--invalid":
                                                    _vm.submitPasswordStep &&
                                                    _vm.errors &&
                                                    _vm.errors.items &&
                                                    _vm.errors.items.find(
                                                      function (item) {
                                                        return (
                                                          item.field ===
                                                            _vm.$t(
                                                              "inputs.password"
                                                            ) &&
                                                          [
                                                            "atLeastOneNumber",
                                                          ].includes(item.rule)
                                                        )
                                                      }
                                                    ),
                                                },
                                              },
                                              [
                                                _vm.password.length &&
                                                !(
                                                  _vm.errors &&
                                                  _vm.errors.items &&
                                                  _vm.errors.items.find(
                                                    function (item) {
                                                      return (
                                                        item.field ===
                                                          _vm.$t(
                                                            "inputs.password"
                                                          ) &&
                                                        [
                                                          "atLeastOneNumber",
                                                        ].includes(item.rule)
                                                      )
                                                    }
                                                  )
                                                )
                                                  ? _c("check-icon", {
                                                      staticClass:
                                                        "register-funnel__main-block__flow__content__form__fields__field__rule__icon",
                                                      attrs: {
                                                        color:
                                                          "rgb(65, 184, 131)",
                                                        width: 16,
                                                        height: 16,
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(
                                                  "\n\n                          " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "inputs.atLeastNumber"
                                                      )
                                                    ) +
                                                    "\n                        "
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "register-funnel__main-block__flow__content__form__fields__field__rule",
                                                class: {
                                                  "register-funnel__main-block__flow__content__form__fields__field__rule--valid":
                                                    _vm.password.length &&
                                                    !(
                                                      _vm.errors &&
                                                      _vm.errors.items &&
                                                      _vm.errors.items.find(
                                                        function (item) {
                                                          return (
                                                            item.field ===
                                                              _vm.$t(
                                                                "inputs.password"
                                                              ) &&
                                                            [
                                                              "atLeastOneSpecialCharacter",
                                                            ].includes(
                                                              item.rule
                                                            )
                                                          )
                                                        }
                                                      )
                                                    ),
                                                  "register-funnel__main-block__flow__content__form__fields__field__rule--invalid":
                                                    _vm.submitPasswordStep &&
                                                    _vm.errors &&
                                                    _vm.errors.items &&
                                                    _vm.errors.items.find(
                                                      function (item) {
                                                        return (
                                                          item.field ===
                                                            _vm.$t(
                                                              "inputs.password"
                                                            ) &&
                                                          [
                                                            "atLeastOneSpecialCharacter",
                                                          ].includes(item.rule)
                                                        )
                                                      }
                                                    ),
                                                },
                                              },
                                              [
                                                _vm.password.length &&
                                                !(
                                                  _vm.errors &&
                                                  _vm.errors.items &&
                                                  _vm.errors.items.find(
                                                    function (item) {
                                                      return (
                                                        item.field ===
                                                          _vm.$t(
                                                            "inputs.password"
                                                          ) &&
                                                        [
                                                          "atLeastOneSpecialCharacter",
                                                        ].includes(item.rule)
                                                      )
                                                    }
                                                  )
                                                )
                                                  ? _c("check-icon", {
                                                      staticClass:
                                                        "register-funnel__main-block__flow__content__form__fields__field__rule__icon",
                                                      attrs: {
                                                        color:
                                                          "rgb(65, 184, 131)",
                                                        width: 16,
                                                        height: 16,
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(
                                                  "\n\n                          " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "inputs.atLeastSpecialCharacter"
                                                      )
                                                    ) +
                                                    "\n                        "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm.currentStep === 3 && !_vm.showVegaActivationMessage
                  ? [
                      _c(
                        "div",
                        {
                          staticClass:
                            "register-funnel__main-block__flow__heading register-funnel__main-block__flow__heading--sized",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "register-funnel__main-block__flow__heading__title",
                            },
                            [_vm._v(_vm._s(_vm.$t("register.greatThatWorked")))]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "register-funnel__main-block__flow__heading__description",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("register.personalizeYourExperience")
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "register-funnel__main-block__flow__content",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "register-funnel__main-block__flow__content__form",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "register-funnel__main-block__flow__content__form__fields",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "register-funnel__main-block__flow__content__form__fields__field",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "register-funnel__main-block__flow__content__form__fields__field__inner",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.companyName,
                                                expression: "companyName",
                                              },
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value:
                                                  "validateStringForSpecialCharacters",
                                                expression:
                                                  "'validateStringForSpecialCharacters'",
                                              },
                                            ],
                                            key: "company-name",
                                            ref: "companyName",
                                            staticClass:
                                              "register-funnel__main-block__flow__content__form__fields__field__input",
                                            attrs: {
                                              type: "text",
                                              name: _vm.$t("vue.companyName"),
                                              "label-placeholder":
                                                _vm.$t("vue.companyName"),
                                            },
                                            domProps: {
                                              value: _vm.companyName,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.companyName =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "register-funnel__main-block__flow__content__form__fields__field__label",
                                              class: {
                                                "register-funnel__main-block__flow__content__form__fields__field__label--moved":
                                                  _vm.companyName &&
                                                  _vm.companyName.length > 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$refs.companyName.focus()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "register.whatIsYourCompanyName"
                                                    )
                                                  ) +
                                                  "\n                      "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm.errors.first(
                                        _vm.$t("vue.companyName")
                                      )
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-danger text-lg flex items-center",
                                              staticStyle: {
                                                "margin-top": "10px",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  src: require("@/assets/images/pages/login/error-icon.svg"),
                                                },
                                              }),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.errors.first(
                                                    _vm.$t("vue.companyName")
                                                  )
                                                ) + "\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "register-funnel__main-block__flow__content__form__fields__field",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "register-funnel__main-block__flow__content__form__fields__field__inner",
                                        },
                                        [
                                          _c(
                                            "multiselect",
                                            {
                                              ref: "industry",
                                              staticClass:
                                                "register-funnel__main-block__flow__content__form__fields__field__select",
                                              attrs: {
                                                options: _vm.industryList,
                                                multiple: false,
                                                "close-on-select": true,
                                                "clear-on-select": false,
                                                placeholder: "",
                                                selectLabel: "",
                                                selectedLabel: "",
                                                deselectLabel: "",
                                                label: "name",
                                                "track-by": "name",
                                                name: "Industry",
                                                "open-direction": "bottom",
                                                "preserve-search": true,
                                                "preselect-first": false,
                                              },
                                              on: {
                                                close: function ($event) {
                                                  _vm.isOpenedIndustrySelect = false
                                                },
                                                open: function ($event) {
                                                  _vm.isOpenedIndustrySelect = true
                                                },
                                              },
                                              model: {
                                                value: _vm.industry,
                                                callback: function ($$v) {
                                                  _vm.industry = $$v
                                                },
                                                expression: "industry",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "noResult" },
                                                [_vm._v("No results found")]
                                              ),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "register-funnel__main-block__flow__content__form__fields__field__label",
                                              class: {
                                                "register-funnel__main-block__flow__content__form__fields__field__label--moved":
                                                  !!_vm.industry ||
                                                  _vm.isOpenedIndustrySelect,
                                                "register-funnel__main-block__flow__content__form__fields__field__label--over":
                                                  _vm.isOpenedIndustrySelect,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$refs.industry.$el.focus()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "register.whatIndustryAreYouIn"
                                                    )
                                                  ) +
                                                  "\n                      "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm.industry &&
                                  _vm.industry.name ===
                                    _vm.$i18n.t("register.retail")
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "register-funnel__main-block__flow__content__form__fields__field",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "register-funnel__main-block__flow__content__form__fields__field__inner",
                                            },
                                            [
                                              _c(
                                                "multiselect",
                                                {
                                                  ref: "industryRetail",
                                                  staticClass:
                                                    "register-funnel__main-block__flow__content__form__fields__field__select",
                                                  attrs: {
                                                    options:
                                                      _vm.industryRetailList,
                                                    multiple: false,
                                                    "close-on-select": true,
                                                    "clear-on-select": false,
                                                    placeholder: "",
                                                    selectLabel: "",
                                                    selectedLabel: "",
                                                    deselectLabel: "",
                                                    label: "name",
                                                    "track-by": "name",
                                                    name: "industryRetail",
                                                    "open-direction": "bottom",
                                                    "preserve-search": true,
                                                    "preselect-first": false,
                                                  },
                                                  on: {
                                                    close: function ($event) {
                                                      _vm.isOpenedIndustryRetailSelect = false
                                                    },
                                                    open: function ($event) {
                                                      _vm.isOpenedIndustryRetailSelect = true
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.industryRetail,
                                                    callback: function ($$v) {
                                                      _vm.industryRetail = $$v
                                                    },
                                                    expression:
                                                      "industryRetail",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "noResult" },
                                                    [_vm._v("No results found")]
                                                  ),
                                                ],
                                                2
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "register-funnel__main-block__flow__content__form__fields__field__label",
                                                  class: {
                                                    "register-funnel__main-block__flow__content__form__fields__field__label--moved":
                                                      !!_vm.industry ||
                                                      _vm.isOpenedIndustryRetailSelect,
                                                    "register-funnel__main-block__flow__content__form__fields__field__label--over":
                                                      _vm.isOpenedIndustryRetailSelect,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$refs.industryRetail.$el.focus()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "register.inWhichArea"
                                                        )
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.industry &&
                                  _vm.industry.name ===
                                    _vm.$i18n.t(
                                      "register.consultingAndCoaching"
                                    )
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "register-funnel__main-block__flow__content__form__fields__field",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "register-funnel__main-block__flow__content__form__fields__field__inner",
                                            },
                                            [
                                              _c(
                                                "multiselect",
                                                {
                                                  ref: "industryConsulting",
                                                  staticClass:
                                                    "register-funnel__main-block__flow__content__form__fields__field__select",
                                                  attrs: {
                                                    options:
                                                      _vm.industryConsultingList,
                                                    multiple: false,
                                                    "close-on-select": true,
                                                    "clear-on-select": false,
                                                    placeholder: "",
                                                    selectLabel: "",
                                                    selectedLabel: "",
                                                    deselectLabel: "",
                                                    label: "name",
                                                    "track-by": "name",
                                                    name: "industryConsulting",
                                                    "open-direction": "bottom",
                                                    "preserve-search": true,
                                                    "preselect-first": false,
                                                  },
                                                  on: {
                                                    close: function ($event) {
                                                      _vm.isOpenedIndustryConsultingSelect = false
                                                    },
                                                    open: function ($event) {
                                                      _vm.isOpenedIndustryConsultingSelect = true
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.industryConsulting,
                                                    callback: function ($$v) {
                                                      _vm.industryConsulting =
                                                        $$v
                                                    },
                                                    expression:
                                                      "industryConsulting",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "noResult" },
                                                    [_vm._v("No results found")]
                                                  ),
                                                ],
                                                2
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "register-funnel__main-block__flow__content__form__fields__field__label",
                                                  class: {
                                                    "register-funnel__main-block__flow__content__form__fields__field__label--moved":
                                                      !!_vm.industry ||
                                                      _vm.isOpenedIndustryConsultingSelect,
                                                    "register-funnel__main-block__flow__content__form__fields__field__label--over":
                                                      _vm.isOpenedIndustryConsultingSelect,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$refs.industryConsulting.$el.focus()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "register.inWhichArea"
                                                        )
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  (_vm.industry ||
                                    _vm.industryRetail ||
                                    _vm.industryConsulting) &&
                                  [
                                    _vm.industry.name,
                                    _vm.industryRetail.name,
                                    _vm.industryConsulting.name,
                                  ].includes(_vm.$i18n.t("register.other"))
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "register-funnel__main-block__flow__content__form__fields__field",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "register-funnel__main-block__flow__content__form__fields__field__inner",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.otherIndustry,
                                                    expression: "otherIndustry",
                                                  },
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value:
                                                      "validateStringForSpecialCharacters",
                                                    expression:
                                                      "'validateStringForSpecialCharacters'",
                                                  },
                                                ],
                                                ref: "otherIndustry",
                                                staticClass:
                                                  "register-funnel__main-block__flow__content__form__fields__field__input",
                                                attrs: {
                                                  type: "text",
                                                  name: "Other industry",
                                                  "label-placeholder":
                                                    _vm.$t("register.other"),
                                                  "data-vv-as":
                                                    _vm.$t("register.other"),
                                                },
                                                domProps: {
                                                  value: _vm.otherIndustry,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.otherIndustry =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "register-funnel__main-block__flow__content__form__fields__field__label",
                                                  class: {
                                                    "register-funnel__main-block__flow__content__form__fields__field__label--moved":
                                                      _vm.otherIndustry &&
                                                      _vm.otherIndustry.length >
                                                        0,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$refs.otherIndustry.focus()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.$t("register.other")
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm.errors.first("Other industry")
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-danger text-lg flex items-center",
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      src: require("@/assets/images/pages/login/error-icon.svg"),
                                                    },
                                                  }),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "Other industry"
                                                      )
                                                    ) + "\n                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "register-funnel__main-block__flow__content__form__fields__field",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "register-funnel__main-block__flow__content__form__fields__field__inner",
                                        },
                                        [
                                          _c(
                                            "multiselect",
                                            {
                                              ref: "interest",
                                              staticClass:
                                                "register-funnel__main-block__flow__content__form__fields__field__select register-funnel__main-block__flow__content__form__fields__field__select-checkbox",
                                              attrs: {
                                                options: _vm.interestList,
                                                multiple: true,
                                                "close-on-select": false,
                                                "clear-on-select": false,
                                                placeholder: "",
                                                selectLabel: "",
                                                selectedLabel: "",
                                                deselectLabel: "",
                                                label: "name",
                                                "track-by": "name",
                                                name: "Interest",
                                                "open-direction": "bottom",
                                                "preserve-search": true,
                                                "preselect-first": false,
                                              },
                                              on: {
                                                close: function ($event) {
                                                  _vm.isOpenedInterestSelect = false
                                                },
                                                open: function ($event) {
                                                  _vm.isOpenedInterestSelect = true
                                                },
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "option",
                                                    fn: function (ref) {
                                                      var option = ref.option
                                                      return [
                                                        _c("div", {
                                                          staticClass:
                                                            "register-funnel__main-block__flow__content__form__fields__field__select__checkbox",
                                                        }),
                                                        _vm._v(
                                                          "\n\n                          " +
                                                            _vm._s(
                                                              option.name
                                                            ) +
                                                            "\n                        "
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2403066554
                                              ),
                                              model: {
                                                value: _vm.interest,
                                                callback: function ($$v) {
                                                  _vm.interest = $$v
                                                },
                                                expression: "interest",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "noResult" },
                                                [_vm._v("No results found")]
                                              ),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "register-funnel__main-block__flow__content__form__fields__field__label",
                                              class: {
                                                "register-funnel__main-block__flow__content__form__fields__field__label--moved":
                                                  (!!_vm.interest &&
                                                    _vm.interest.length) ||
                                                  _vm.isOpenedInterestSelect,
                                                "register-funnel__main-block__flow__content__form__fields__field__label--over":
                                                  _vm.isOpenedInterestSelect,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$refs.interest.$el.focus()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "register.whatDoYouWantToAchieveWithContactNow"
                                                    )
                                                  ) +
                                                  "\n                      "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm.isOtherInterest
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "register-funnel__main-block__flow__content__form__fields__field",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "register-funnel__main-block__flow__content__form__fields__field__inner",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.otherInterest,
                                                    expression: "otherInterest",
                                                  },
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value:
                                                      "validateStringForSpecialCharacters",
                                                    expression:
                                                      "'validateStringForSpecialCharacters'",
                                                  },
                                                ],
                                                ref: "otherInterest",
                                                staticClass:
                                                  "register-funnel__main-block__flow__content__form__fields__field__input",
                                                attrs: {
                                                  type: "text",
                                                  name: "Other interest",
                                                  "label-placeholder":
                                                    _vm.$t("register.other"),
                                                  "data-vv-as":
                                                    _vm.$t("register.other"),
                                                },
                                                domProps: {
                                                  value: _vm.otherInterest,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.otherInterest =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "register-funnel__main-block__flow__content__form__fields__field__label",
                                                  class: {
                                                    "register-funnel__main-block__flow__content__form__fields__field__label--moved":
                                                      _vm.otherInterest &&
                                                      _vm.otherInterest.length >
                                                        0,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$refs.otherInterest.focus()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.$t("register.other")
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm.errors.first("Other interest")
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-danger text-lg flex items-center",
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      src: require("@/assets/images/pages/login/error-icon.svg"),
                                                    },
                                                  }),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "Other interest"
                                                      )
                                                    ) + "\n                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  : _vm._e(),
                _vm.currentStep === 3 && _vm.showVegaActivationMessage
                  ? [
                      _c(
                        "div",
                        {
                          staticClass:
                            "register-funnel__main-block__flow__heading register-funnel__main-block__flow__heading--sized",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "register-funnel__main-block__flow__heading__offer",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("register.vega.exclusiveOffer"))
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "register-funnel__main-block__flow__heading__title",
                            },
                            [_vm._v(_vm._s(_vm.$t("register.vega.mainTitle")))]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "register-funnel__main-block__flow__heading__description register-funnel__main-block__flow--no-bottom",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("register.vega.subTitle")) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "register-funnel__main-block__flow__vega__benefits",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "register-funnel__main-block__flow__vega__benefits__box",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "register-funnel__main-block__flow__vega__benefits__box__icon",
                                    },
                                    [_c("VegaIcon")],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "register-funnel__main-block__flow__vega__benefits__box__header",
                                    },
                                    [_vm._v("+300%")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "register-funnel__main-block__flow__vega__benefits__box__description",
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t("register.vega.benefits1")
                                          ) +
                                          "\n                  "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "register-funnel__main-block__flow__vega__benefits__box",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "register-funnel__main-block__flow__vega__benefits__box__header",
                                    },
                                    [_vm._v("+300%")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "register-funnel__main-block__flow__vega__benefits__box__description",
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t("register.vega.benefits2")
                                          ) +
                                          "\n                  "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "register-funnel__main-block__flow__vega__benefits__box",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "register-funnel__main-block__flow__vega__benefits__box__header",
                                    },
                                    [
                                      _c("RatingSuccessIcon", {
                                        attrs: {
                                          width: 30.13,
                                          height: 30.13,
                                          stroke: "#3034F7",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "register-funnel__main-block__flow__vega__benefits__box__description register-funnel__main-block__flow__vega__benefits__box__description--icon",
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t("register.vega.benefits3")
                                          ) +
                                          "\n                  "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "register-funnel__main-block__flow__vega__description",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("register.vega.description"))
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("div", {
                        staticClass:
                          "register-funnel__main-block__flow__content",
                      }),
                    ]
                  : _vm._e(),
                _vm.accountCreationSuccessful ? [_vm._m(1)] : _vm._e(),
                _vm.currentStep === 4 &&
                !_vm.isOpenedFeaturesListOnMobile &&
                _vm.company &&
                !_vm.company.IS_LOTTERY_WINNER
                  ? [
                      _c(
                        "div",
                        {
                          staticClass:
                            "register-funnel__main-block__flow__heading register-funnel__main-block__flow__heading--sized",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "register-funnel__main-block__flow__heading__title",
                            },
                            [_vm._v(_vm._s(_vm.$t("register.selectYourPlan")))]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "register-funnel__main-block__flow__heading__description register-funnel__main-block__flow__heading__description--thin",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t(
                                      "register.youWontBeChargedUntilAfterYourTrialEnds"
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  : _vm._e(),
                _vm.currentStep === 5
                  ? [
                      _c(
                        "div",
                        {
                          staticClass:
                            "register-funnel__main-block__flow__heading register-funnel__main-block__flow__heading--sized",
                          class: {
                            "register-funnel__main-block__flow__heading--margin":
                              _vm.$i18n.locale === "de",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "register-funnel__main-block__flow__heading__title",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("register.setupYourAccount"))
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "register-funnel__main-block__flow__heading register-funnel__main-block__flow__heading--sized",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "register-funnel__main-block__flow__heading__description register-funnel__main-block__flow__heading__description--thin",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t(
                                          "register.youWontBeChargedUntilAfterYourTrialEnds"
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("Mollie", {
                        attrs: {
                          btnTitle: _vm.$t("vue.goToYourAccount"),
                          subscriptionPlanId: _vm.subscriptionPlanId,
                          selectedPlan: _vm.selectedPlan,
                          isFirstInvoice: true,
                          supportedPaymentMethods: _vm.supportedPaymentMethods,
                          redirectUrl: _vm.mollieRedirectUrl,
                          IS_LOTTERY_WINNER: _vm.IS_LOTTERY_WINNER,
                        },
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm.currentStep === 4
              ? [
                  _c("MolliePricingPlans", {
                    attrs: {
                      isOpenedFeaturesListOnMobile:
                        _vm.isOpenedFeaturesListOnMobile,
                      isOpenedFeaturesList: _vm.isOpenedFeaturesList,
                      isOpenedBasicFeaturesList: _vm.isOpenedBasicFeaturesList,
                      isOpenedProFeaturesList: _vm.isOpenedProFeaturesList,
                      isOpenedEnterpriseFeaturesList:
                        _vm.isOpenedEnterpriseFeaturesList,
                      featuresList: _vm.featuresList,
                      isLotteryWinner: _vm.company
                        ? Boolean(_vm.company.IS_LOTTERY_WINNER)
                        : false,
                    },
                    on: { "select-plan": _vm.selectPlan },
                  }),
                ]
              : _vm._e(),
            _vm.currentStep == 3 &&
            !_vm.showVegaActivationMessage &&
            _vm.company &&
            !_vm.company.IS_LOTTERY_WINNER
              ? _c(
                  "div",
                  {
                    staticClass:
                      "register-funnel__main-block__flow__content__next-button-wrapper",
                  },
                  [
                    _vm.currentStep !== 5
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "register-funnel__main-block__flow__content__next-button",
                            on: { click: _vm.goToYourAccount },
                          },
                          [
                            [_vm._v(_vm._s(_vm.$t("vue.goToYourAccount")))],
                            _c("img", {
                              staticClass:
                                "register-funnel__main-block__flow__content__next-button__icon",
                              attrs: {
                                src: require("@/assets/images/register-funnel/forward-arrow.png"),
                                alt: "Next",
                              },
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm.currentStep == 3 &&
            _vm.showVegaActivationMessage &&
            _vm.company &&
            !_vm.company.IS_LOTTERY_WINNER
              ? _c(
                  "div",
                  {
                    staticClass:
                      "register-funnel__main-block__flow__content__next-button-wrapper",
                  },
                  [
                    _vm.currentStep !== 5
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "register-funnel__main-block__flow__content__next-button",
                            on: { click: _vm.goToYourAccount },
                          },
                          [
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("register.vega.activateVegaButton")
                                )
                              ),
                            ],
                            _c("img", {
                              staticClass:
                                "register-funnel__main-block__flow__content__next-button__icon",
                              attrs: {
                                src: require("@/assets/images/register-funnel/forward-arrow.png"),
                                alt: "Next",
                              },
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            (_vm.currentStep <= _vm.totalCountOfSteps &&
              _vm.currentStep != 4 &&
              _vm.currentStep !== 3) ||
            (_vm.company && _vm.company.IS_LOTTERY_WINNER)
              ? _c(
                  "div",
                  {
                    staticClass:
                      "register-funnel__main-block__flow__content__next-button-wrapper",
                  },
                  [
                    _vm.currentStep !== 5
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "register-funnel__main-block__flow__content__next-button",
                            on: { click: _vm.nextStep },
                          },
                          [
                            [_vm._v(_vm._s(_vm.$t("vue.nextStep")))],
                            _c("img", {
                              staticClass:
                                "register-funnel__main-block__flow__content__next-button__icon",
                              attrs: {
                                src: require("@/assets/images/register-funnel/forward-arrow.png"),
                                alt: "Next",
                              },
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm.currentStep === 3
              ? _c(
                  "div",
                  { staticClass: "register-funnel__main-block__footer" },
                  [
                    _c("div", { staticClass: "text-center" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "register-funnel__main-block__footer__description register-funnel__main-block__footer__description--hover",
                          on: { click: _vm.skipStep },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.showVegaActivationMessage
                                  ? _vm.$t("vue.skip")
                                  : _vm.$t("vue.skipThisStepDoItLater")
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.currentStep != 4 && !_vm.showVegaActivationMessage
              ? _c(
                  "div",
                  { staticClass: "register-funnel__main-block__footer" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "register-funnel__main-block__footer__description",
                      },
                      [
                        _vm.$i18n.locale === "de"
                          ? [
                              _vm._v(
                                "\n              Mit Abschluss der Registrierung erklärst du dich damit einverstanden, dass wir deine Daten wie in der\n              "
                              ),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "register-funnel__main-block__footer__description__link",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.openExternalFile(
                                        "https://storage.googleapis.com/pathadvice-app.appspot.com/legal/2023/A3100D_Lizenzvertrag_Datenverarbeitung_LetsConnect_DE_20221018.pdf"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                Lizenzvereinbarung und Datenauftragsverarbeitungsvereinbarung\n              "
                                  ),
                                ]
                              ),
                              _vm._v(
                                "\n              der PATHADVICE International GmbH beschrieben (einschl. US-Datenbezug) verarbeiten.\n            "
                              ),
                            ]
                          : [
                              _vm._v(
                                "\n              By creating your account, you agree that we may process your data as described in the\n              "
                              ),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "register-funnel__main-block__footer__description__link",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.openExternalFile(
                                        "https://storage.googleapis.com/pathadvice-app.appspot.com/legal/2023/A3100E_LicenseAgreement_DataProcessing_Agreement_LetsConnect_EN_20221018.pdf"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                License Agreement and Data Order Processing Agreement\n              "
                                  ),
                                ]
                              ),
                              _vm._v(
                                "\n              (including US data reference).\n            "
                              ),
                            ],
                      ],
                      2
                    ),
                  ]
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]),
    !_vm.showVegaActivationMessage
      ? _c("div", { staticClass: "register-funnel__info-block" }, [
          _c("div", { staticClass: "register-funnel__info-block__inner" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/register-funnel/people-1.png"),
                alt: "Hi there!",
              },
            }),
            _c("div", { staticClass: "register-funnel__info-block__title" }, [
              _vm._v(_vm._s(_vm.$t("vue.registerFunnelInfoBlockTitle"))),
            ]),
            _c(
              "div",
              { staticClass: "register-funnel__info-block__description" },
              [_vm._v(_vm._s(_vm.$t("vue.registerFunnelInfoBlockDescription")))]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.showVegaActivationMessage
      ? _c("div", { staticClass: "register-funnel__info-block" }, [
          _c("div", { staticClass: "register-funnel__info-block__inner" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/register-funnel/vega-analyze.png"),
                width: "285",
                height: "207",
                alt: "Vega analyze",
              },
            }),
            _c("div", { staticClass: "register-funnel__info-block__title" }, [
              _vm._v(_vm._s(_vm.$t("register.vega.rightHeader"))),
            ]),
            _c(
              "div",
              { staticClass: "register-funnel__info-block__description" },
              [_vm._v(_vm._s(_vm.$t("register.vega.rightDescription")))]
            ),
            _c("div", { staticClass: "register-funnel__info-block__link" }, [
              _c(
                "a",
                { attrs: { href: "https://letsconnect.at", target: "_blank" } },
                [_vm._v(_vm._s(_vm.$t("register.vega.rightLink")))]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.isLoadingRegisterFunnel
      ? _c("div", { staticClass: "register-funnel__loading" }, [_vm._m(2)])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "register-funnel__main-block__heading__logo-wrapper",
        attrs: { href: "https://www.letsconnect.at/" },
      },
      [
        _c("img", {
          staticClass: "register-funnel__main-block__heading__logo-img",
          attrs: {
            src: require("@/assets/images/register-funnel/vega_logo.svg"),
            alt: "Logo",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mollie-checkout__loading" }, [
      _c("div", { staticClass: "mollie-checkout__loading__loader" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/register-funnel/check.png"),
            alt: "Success",
          },
        }),
        _c("div", { staticClass: "mollie-checkout__loading__loader__title" }, [
          _vm._v("All good!"),
        ]),
        _c("div", { staticClass: "mollie-checkout__loading__loader__text" }, [
          _vm._v("We'll redirect you to your account"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "register-funnel__loading__loader" }, [
      _c("span", { staticClass: "register-funnel__loading__loader__spinner" }),
      _c("div", { staticClass: "register-funnel__loading__loader__text" }, [
        _vm._v("Processing..."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }