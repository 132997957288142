var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.$i18n.locale === "de"
      ? _c("div", { staticClass: "lottery-message" }, [
          _c("div", { staticClass: "lottery-message__title-lottery" }, [
            _vm._v("Herzlichen Glückwunsch 🎉"),
          ]),
          _vm._m(0),
        ])
      : _c("div", { staticClass: "lottery-message" }, [
          _c("div", { staticClass: "lottery-message__title-lottery" }, [
            _vm._v("Congratulations 🎉"),
          ]),
          _vm._m(1),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lottery-message--lottery" }, [
      _vm._v(
        "\n      Wir gratulieren - Du bist 1 von 100! Jetzt registrieren & unseren beliebten\n      "
      ),
      _c("strong", [_vm._v("Pro Plan")]),
      _vm._v("\n      im Wert von € 279/Monat = € 3.348/Jahr für\n      "),
      _c("span", { staticClass: "lottery-message__bluefont" }, [
        _c("strong", [_vm._v("unglaubliche €27,90/Monat = 334,80/Jahr ")]),
      ]),
      _vm._v(
        "\n      erhalten. Sichere Dir das einmalige Angebot und profitiere ein Leben lang! Du sparst dir\n      "
      ),
      _c("strong", [_vm._v("jedes Jahr mehr als € 3.000.")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lottery-message--lottery" }, [
      _vm._v(
        "\n      Congratulations on the Let's Connect business promotion, giving you our popular\n      "
      ),
      _c("strong", [_vm._v("Pro Plan")]),
      _vm._v(" worth €279/monthly =   € 3.348/yearly for\n      "),
      _c("span", { staticClass: "lottery-message__bluefont" }, [
        _c("strong", [_vm._v("incredible €27,90/monthly = 334,80/yearly")]),
      ]),
      _vm._v("\n      . Get your profit now and benefit "),
      _c("span", { staticClass: "text-span-7" }, [_vm._v("for a ")]),
      _c("span", { staticClass: "text-span-6" }, [_vm._v("lifetime")]),
      _vm._v("! You'll\n      "),
      _c("strong", [_vm._v("save more than € 3.000 every year.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }