<!-- =========================================================================================
    File Name: Register.vue
    Description: Register Page
    ----------------------------------------------------------------------------------------
    Author: PATHADVICE
    Author URL: https://pathadvice.ai
========================================================================================== -->

<template>
  <div class="register-page">
    <!-- <div class="bg-overlay"></div>
    <dummy-background />
    <auth-navbar hideActions="true" /> -->
    <register-funnel />
  </div>
</template>

<script>
import 'firebase/functions'
import 'firebase/firestore'
import 'firebase/storage'

// import DummyBackground from '@/components/dummy-background/DummyBackground.vue'
// import AuthNavbar from '@/components/auth/AuthNavbar.vue'
// import RegisterFirebase from './RegisterFirebase.vue'
import RegisterFunnel from './RegisterFunnel.vue'

import { commonFunctions } from '@/mixins/commonFunctions'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default {
  components: {
    // DummyBackground,
    // AuthNavbar,
    // RegisterFirebase,
    RegisterFunnel
  },
  data() {
    return {
      trialDays: '',
      imageUrl: null
    }
  },
  mixins: [commonFunctions],
  computed: {
    locale() {
      return this.$i18n.locale
    },
    defaultTrialPeriod() {
      return this.getParameterByName('tokenID') || this.getCookie('tokenID') ? 45 : 30
    }
  },
  beforeDestroy() {
    this.$serverBus.$off('invoice-created')
    this.$serverBus.$off('close-mollie-form')
  },
  async mounted() {
    const vm = this
    vm.$vs.loading()
    const afid = vm.getCookie('afid')
    const cpid = vm.getCookie('cpid')
    if (afid && cpid) {
      const getCouponInfoById = vm.$functions.httpsCallable('getCouponInfoById')
      const res = await getCouponInfoById({ couponId: cpid })
      if (res && res.data && res.data.coupon) {
        const coupon = res.data.coupon
        if (coupon.type === 'expire-after-days' && coupon.expireAfterDays) {
          vm.trialDays = coupon.expireAfterDays
        }
        if (coupon.type === 'expiry-date' && coupon.expiryDate) {
          const expiryDate = dayjs.unix(coupon.expiryDate._seconds).tz(this.$defaultTimezone)
          const today = dayjs().tz(this.$defaultTimezone)
          const diff = expiryDate.diff(today, 'day')
          vm.trialDays = diff >= this.defaultTrialPeriod ? diff : this.defaultTrialPeriod
        }
      } else {
        vm.trialDays = this.defaultTrialPeriod
      }
    } else {
      vm.trialDays = this.defaultTrialPeriod
    }
    setTimeout(() => {
      vm.$vs.loading.close()
    }, 100)
    this.$serverBus.$on('invoice-created', async () => {
      document.getElementById('pa-overlay').style.display = 'block'
      const firebaseCurrentUser = this.$firebase.auth().currentUser
      await firebaseCurrentUser.getIdToken(true)
      window.location.href = '/payment/success'
    })
    this.$serverBus.$on('close-mollie-form', async () => {
      document.getElementById('pa-overlay').style.display = 'block'
      const firebaseCurrentUser = this.$firebase.auth().currentUser
      await firebaseCurrentUser.getIdToken(true)
      window.location.href = '/payment/success'
    })
  }
}
</script>
